import { getFragmentData } from '@/graphql/__generated__';
import { BLOCKS_FRAGMENT } from '@/graphql/fragments/blocks';
import { MaskedBlockProps, RawBlocks } from '@/lib/parsers/blocks';
import { RICH_TEXT_BLOCK } from './BlockComponents/RichTextBlock';

export const createBlockMeta = <T,>(blocks: RawBlocks): MaskedBlockProps<T>[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return blocks
    .map((v, i) => {
      if (!v) return null;

      const data = getFragmentData(BLOCKS_FRAGMENT, v);
      const type = data.__typename;
      let maybeAnchor: string | null | undefined = null;

      if (type === 'blocks_richText_BlockType') {
        const richTextBlock = getFragmentData(RICH_TEXT_BLOCK, data);
        maybeAnchor = richTextBlock.heading;
      }

      nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;

      return {
        data: v,
        meta: {
          typename: type,
          nthOfType: nthOfTypeCount[type],
          index: i,
          first: i === 0,
          last: i === blocks.length - 1,
          length: blocks.length,
          anchor: maybeAnchor,
        },
      };
    })
    .filter(Boolean) as MaskedBlockProps<T>[];
};
