'use client';

import { ArticleCardFragment } from '@/graphql/__generated__/graphql';
import { useFragment } from '@apollo/client';
import React from 'react';
import ArticleCard, { ARTICLE_CARD_FRAGMENT } from '../ArticleCard';

export default function Result({ id }: { id: string }) {
  const { data } = useFragment({
    fragment: ARTICLE_CARD_FRAGMENT,
    fragmentName: 'articleCard',
    from: { __typename: 'article_Entry', id },
  });
  const [article, setResult] = React.useState<ArticleCardFragment>();

  React.useEffect(() => {
    if (data) setResult(data as ArticleCardFragment);
  }, [data]);

  if (!article) return null;

  return <ArticleCard data={article} />;
}
