'use client';

import { useEntrySearchStore } from '@liquorice/next-search';
import Grid from '../ui/Grid';
import Result from './Result';

export default function Results() {
  const results = useEntrySearchStore((s) => s.results);

  return (
    <Grid rowGutter>
      {results.map((result) => (
        <Grid.Col key={result.id} md={4}>
          <Result id={result.id} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
