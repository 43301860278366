'use client';

import { useChoice } from '@liquorice/next-search';
import Grid from '../ui/Grid';
import CategorySelectField from './CategorySelectField';

const Filters = () => {
  const { selected } = useChoice({ name: 'section' });

  const isArticles = selected.includes('article');

  return (
    <Grid cx={{ mB: '6xl' }}>
      <Grid.Col xs={10} md={4}>
        {isArticles && <CategorySelectField allLabel="All categories" name="category" />}
      </Grid.Col>
    </Grid>
  );
};

export default Filters;
