import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/components/Blocks/createBlock';
import { gql } from '@/graphql/__generated__';
import { parseColorScheme } from '@/lib/parsers/colorScheme';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { BlockContainer } from '../../BlockContainer';

export const RICH_TEXT_BLOCK = gql(`
  fragment richTextBlock on blocks_richText_BlockType {
      heading
      content: htmlContentDefault
      blockScheme {
        label
      }
  }   
`);

const RichTextBlock = createBlock(RICH_TEXT_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const { heading } = data;
  const content = cleanHtml(data.content);
  const colorSet = parseColorScheme(data.blockScheme);

  return (
    <BlockContainer meta={{ anchor: heading, ...meta }} colorSet={colorSet}>
      <BlockHeader heading={heading} content={content} />
    </BlockContainer>
  );
});

export default RichTextBlock;
