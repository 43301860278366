'use client';

import { usePagination } from '@liquorice/next-search';
import IconButton from '../IconButton';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';

type PaginationNumberProps = {
  number: number;
  onClick: () => void;
  currentPage: number;
};

const PaginationNumber = ({ number, onClick, currentPage }: PaginationNumberProps) => {
  return (
    <Btn
      variant="text"
      size="large"
      color="black"
      onClick={onClick}
      underline={currentPage === number ? 'always' : 'hover'}>
      {number}
    </Btn>
  );
};

const Pagination = () => {
  const { page, totalPages, setPage, nextPage, prevPage, firstPage, lastPage } = usePagination();
  const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1).filter(
    (i) => i > page - 3 && i < page + 3 && i !== 1 && i !== totalPages
  );

  return (
    <Flex direction="row" spacing="md" justifyContent="center" cx={{ mT: '6xl' }}>
      <IconButton icon="chevronLeft" color="black" onClick={prevPage} disabled={page === 1} />
      <PaginationNumber number={1} onClick={firstPage} currentPage={page} />
      {page > 4 && <span>&hellip;</span>}
      {pagesArray.map((i) => (
        <PaginationNumber key={i} number={i} onClick={() => setPage(i)} currentPage={page} />
      ))}
      {page < totalPages - 3 && <span>&hellip;</span>}
      {totalPages !== 1 && (
        <PaginationNumber number={totalPages} onClick={lastPage} currentPage={page} />
      )}
      <IconButton
        icon="chevronRight"
        color="black"
        onClick={nextPage}
        disabled={page === totalPages}
      />
    </Flex>
  );
};

export default Pagination;
