'use client';

import { BlocksProps } from '@/lib/parsers/blocks';
import { slugify } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import React from 'react';
import { createBlockMeta } from '../Blocks/createBlockMeta';
import { useNavBarActions } from '../NavBar/useNavBar';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import * as styles from './PolicyMeta.css';

export default function PolicyMeta({ blocks = [] }: BlocksProps) {
  const [, setActive] = React.useState<boolean>(false);
  const { height, fallbackHeight } = useNavBarActions();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute('id');
        const tableElements = document.querySelector(`aside div a[href="#${id}"]`);
        if (entry.intersectionRatio > 0) {
          if (tableElements) {
            tableElements.classList.add('active');
          }
        } else {
          if (tableElements) {
            tableElements.classList.remove('active');
          }
        }
      });
    });

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  });

  if (!blocks) return null;

  const sanitisedBlocks = createBlockMeta(blocks) ?? blocks;

  return (
    <Flex
      alignItems="start"
      spacing="2xs"
      className={styles.root}
      style={assignInlineVars({
        [styles.navbarHeight]: `${height ?? fallbackHeight}px`,
      })}>
      {sanitisedBlocks.map(({ meta }) => {
        if (meta?.anchor)
          return (
            <Btn
              key={slugify(meta.anchor)}
              variant="text"
              size="normal"
              wrap
              onClick={() => setActive(false)}
              href={`#${slugify(meta.anchor)}`}>
              {meta.anchor}
            </Btn>
          );
      })}
    </Flex>
  );
}
