import { gql } from '@/graphql/__generated__';
import { ArticleCardFragment } from '@/graphql/__generated__/graphql';
import { articleCategoryParser } from '@/graphql/fragments/categories';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import Card, { CardProps } from '../Card';

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment articleCard on article_Entry {
    __typename
    id
    uri
    postDate @formatDateTime(format: "j F Y")
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    articleCategory {
      ...articleCategory
    }
  }
`);

type ArticleCardProps = { data: ArticleCardFragment } & Omit<CardProps, 'item'>;

const ArticleCard = ({ data, ...props }: ArticleCardProps) => {
  const { entryTitle, entrySummary, entryImage, articleCategory, postDate, uri } = data;
  const categories = articleCategoryParser(firstNonNullable(articleCategory));
  const href = parseUri(uri);

  return (
    <Card
      padding="lg"
      rounded
      fullHeight
      colorSet="white"
      paper
      item={{
        title: entryTitle,
        description: entrySummary,
        image: entryImage,
        meta: categories?.title,
        date: postDate,
        href,
      }}
      {...props}
    />
  );
};

export default ArticleCard;
