'use client';

import { UseCategoriesOptions, useCategoryChoice } from '@liquorice/next-search';
import React from 'react';
import Box from '../ui/Box';

export interface CategorySelectFieldProps extends UseCategoriesOptions {
  allLabel?: string;
}

const CategorySelectField = ({ allLabel = 'All', ...config }: CategorySelectFieldProps) => {
  const { options, ...choice } = useCategoryChoice(config);

  const handleChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = Array.from(ev.target.selectedOptions).map((option) => option.value);
    choice.setValue(selected);
  };

  return (
    <Box
      as="select"
      aria-label={config.name}
      name={choice.name}
      multiple={choice.multiple}
      value={choice.value}
      onChange={handleChange}
      cx={{ p: 'xs', width: '100', borderRadius: 'sm' }}>
      <Box as="option" value="">
        {allLabel}
      </Box>
      {options?.map((option) => (
        <Box as="option" key={option.value} value={option.value}>
          {option.label}
        </Box>
      ))}
    </Box>
  );
};

export default CategorySelectField;
