import { gql } from '../__generated__';

export const BLOCKS_FRAGMENT = gql(`
    fragment blocks on blocks_NeoField {
        ...richTextBlock
        ...headingBlock
        ...quoteBlock
        ...buttonBlock
        ...ctaBlock
        ...lineBlock
        ...priceBlock
        ...pricingBlock
        ...accordionBlock
        ...tabbedContentBlock
        ...twoColumnContentBlock
        ...twoColumnGroupBlock
        ...threeColumnItemBlock
        ...threeColumnContentBlock
        ...scrollContentBlock
        ...testimonialItemBlock
        ...testimonialBlock
        ...staffBlock
        ...typeformBlock
        ...newsletterSignupBlock
        ...videoBlock
        ...imageBlock
        ...twoImagesBlock
        ...logoWallBlock
        ...downloadsBlock
        ...formBlock
    }    
`);
