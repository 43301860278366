import { createFragmentParser } from '@liquorice/gql-utils';
import { gql } from '../__generated__';

export const ARTICLE_CATEGORY_FRAGMENT = gql(`
    fragment articleCategory on articleCategory_Category {
      __typename
      id
      uri
      slug
      groupHandle
      title
    }
`);

export const articleCategoryParser = createFragmentParser(
  ARTICLE_CATEGORY_FRAGMENT,
  (data) => data
);
